<template>
  <v-app id="inspire">
    <v-navigation-drawer color="#222832" v-model="drawer" app>
      <v-container class="text-center">
        <v-avatar size="80px">
          <img
              v-if="user.photo"
              :src="user.photo"
          />
          <v-icon v-else color="var(--main)" large>
            mdi-account-circle-outline
          </v-icon>
        </v-avatar>
      </v-container>
      <v-container class="text-light text-center p-0">
        <span>
          {{ user.firstname + " " + user.lastname }}
        </span>
      </v-container>

      <v-list>
        <router-link to="home">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon color="deep-orange">mdi-home</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="color: #f06d34" class="font-weight-bold"
              >Home
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <router-link to="posts">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon color="deep-orange">mdi-post-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="color: #f06d34" class="font-weight-bold"
              >Posts
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <router-link to="coupons">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="deep-orange">mdi-ticket-percent</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="color: #f06d34" class="font-weight-bold"
              >Coupons
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list>

      <template v-slot:append>
        <div class="pa-2 w-100 text-center">
          <v-btn-toggle class="bg-transparent" >
            <v-btn target="_blank" href="https://www.app-privacy-policy.com/live.php?token=ViZok5iWaLqR8XO3Iw6Tr54W7vDotEF7" color="transparent" class="text-light" small>EULA</v-btn>
<!--            <v-btn href="https://www.app-privacy-policy.com/live.php?token=ViZok5iWaLqR8XO3Iw6Tr54W7vDotEF7" small>Privacy Policy</v-btn>-->
          </v-btn-toggle>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar app elevation="0" color="var(--accent)">
      <v-app-bar-nav-icon
          @click="drawer = !drawer"
          color="var(--main)"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title style="color: var(--main)">
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar dark v-bind="attrs" v-on="on">
              <img
                  v-if="user.photo"
                  :src="user.photo"
                  alt="John"
              />
              <v-icon v-else large color="var(--main)">
                mdi-account-circle-outline
              </v-icon>
            </v-avatar>
          </template>
          <v-list dense class="p-0" >
            <v-list-item-group>
              <router-link to="profile">
                <v-list-item>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              </router-link>
              <v-list-item>
                <v-list-item-title>About Us</v-list-item-title>
              </v-list-item>
              <v-list-item class="bg-danger text-light" >
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data: () => ({drawer: null}),
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style scoped>
:root {
  --main: #f06d34;
  --accent: #222832;
}
</style>
